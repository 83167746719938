import { createGlobalStyle } from "styled-components"


export const colors = {
  white: "white",
  darkGrey: "rgba(0,0,0,.7)",
  deepDarkGrey: "#404040",
  blueShade1: "#215973",
  blueShade2: "#6fadc6",
  blueShade3: "#d1e1e9",


  blue: "#041B77",
  orange: "#FA7322",
  lightblack: "rgba(0,0,0,.7)",

}

export const darkTheme = {
  colors: {
    background: colors.darkGrey,
    menuBackground: colors.blue,
    textDark: colors.blueShade3,
    textMain: colors.blueShade2,
    textSecondary: colors.blueShade3,

    white: colors.white,
    orange: colors.orange,
    textcolor: colors.lightblack,
    blue: colors.blue,



    
  },
}



export const GlobalStyles = createGlobalStyle`
  


#gatsby-focus-wrapper {
  max-width:1920px;
  margin:0 auto;
  overflow:hidden;
}

  .orange {
    background:red;
  }

  body {
    margin:-1px;
   
    transition: all 0.5s ease;
  }

  

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    transition: all 0.5s ease;
    letter-spacing: 2px;
    font-weight: 400;
  }

  h2 {
    font-size:3rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.2;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;


    @media screen and (min-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  h2 {
    font-size: 1rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
     
    }
  }

  h3 {
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    margin: 30px 0 -15px 0;

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  a {
    text-decoration: none;
  }

  .page-hero-img {
    height:0vh;
    width:100%;
    border-radius:0;
    position:relative;
    display:none;
    @media screen and (min-width: 768px) {
      height:50vh;
    }
  
    :before {
      content:"";
      height:100%;
      position:absolute;
      top:0;
      width:100%;
      background:rgba(0,0,0,.6);
      z-index:1;
    }
    @media screen and (min-width: 768px) {
      .page-hero-img {
        height:60vh;
      }

      }

  }


  .subpage-content {
    text-align:center;
    height:70vh;
    display:flex;
    align-items:center;
    h1 {
      position: absolute;
      top: 20vh;
      color: white;
      text-align: center;
      width: 100%;
      font-size: 3rem;
      z-index:3;
      max-width:1920px;
    }
    .subpage-text {
      width:80%;
      padding:15px;
      padding-bottom:35px;
      font-family: 'Nunito', sans-serif;
      text-align:center;
      margin:0 auto;
      display:none;


      >div {
        ul {
          text-align:left;
        }
      }

      h2 {
        text-align:center;
        font-size:2rem;
        color:rgba(0,0,0,.7);

      }
      p {
        font-size:1.2rem;
      }
      .offert-text-holder {
        display:flex;
        flex-direction:column;
        width:100%;

        @media screen and (min-width: 768px) {
         flex-direction:row;
          }

        >div {
          width:100%;
          font-family: 'Nunito', sans-serif;
          font-size:1.2rem;
          

          @media screen and (min-width: 768px) {
            width:50%;
            padding:35px;
            }

          p {
            font-size:1.2rem;
          }
        }

        ul {
          
          width: 100%;
          text-align: center;
          margin-top: 35px;
          font-size: 1.2rem;

          @media screen and (min-width: 768px) {
            text-align:left;
            width:50%;
            margin:0;
            padding: 35px;

            }

          li {
            list-style-type:none;
            font-family: 'Nunito', sans-serif;
            padding:5px;
            :nth-child(odd) {
              background:#f7f7f7;
            }
          }
        }
        
      }
    }
    @media screen and (min-width: 768px) {
      .subpage-content {
        h1 {
          font-size:4rem;
        }
        
      }

      .subpage-text {
        padding:35px;

      }
    
    }
  }
  .mastercard {
    width:70%;
    margin-bottom:35px;

    @media screen and (min-width: 768px) {
      width:20%;
      margin:15px auto;
      }
}


  
  .knappar-subpage {
    margin: 0 auto;
    position: absolute;
    top: 50vh;
    width: 100%;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    z-index:3;
    @media screen and (min-width: 667px) {
    flex-direction:row;  
      }

    a {
        background:${props => props.theme.colors.orange};
        color:black;
        font-weight:bold;
        transition:.3s;
        padding:5px 10px;
        border: 1px solid ${props => props.theme.colors.orange};
        flex: 1 1 0;
        font-size:1.2rem;
        width:50%;
        max-width:100%;
        font-family:'Nunito', sans-serif;
        text-transform:uppercase;
        text-align:center;
        border-radius:50px;
        
        margin:10px;
        

        :hover {
            color:white;
            background:transparent;
            border:1px solid white;
        }
    }
    @media screen and (min-width: 768px) {
     
        top:40vh;
        width:50%;
        flex-direction:row;
        left: 25%;

        a {
          max-width:100px;
          font-size:.75rem;
          width:20%;
        }
        
      
    }
    @media screen and (min-width: 1600px) {
      top:30vh;
    }
  } 
`

