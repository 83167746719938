import { useStaticQuery, graphql } from 'gatsby';



export const useBilderDataQuery = () => {
    const data = useStaticQuery(graphql`

    query BilderQuery {
        markdownRemark(frontmatter: {type: {eq: "bilder"}}) {
            
                frontmatter {
                    bildtext
                    imgtext
                    hero {
                        childImageSharp{
                            fluid(maxWidth:1600){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    herobg {
                        childImageSharp{
                            fluid(maxWidth:1600){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    tjanstett {
                        childImageSharp{
                            fluid(maxWidth:800){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    tjansttva {
                        childImageSharp{
                            fluid(maxWidth:800){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    tjansttre {
                        childImageSharp{
                            fluid(maxWidth:800){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    } 
                    iconone {
                        childImageSharp{
                            fluid(maxWidth:800){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    } 
                    icontwo {
                        childImageSharp{
                            fluid(maxWidth:800){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    } 
                    iconthree {
                        childImageSharp{
                            fluid(maxWidth:800){
                                ...GatsbyImageSharpFluid 
                            }
                        }
                    }                  
            }
        }
    }
    
    
    `)
    return data.markdownRemark.frontmatter

}